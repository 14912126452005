import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

export default {
  name: "rideCard",
  mixins: [mixins],
  components: {},
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      isAdd: false,
      parkType: "",
      address: null,
      type: 'LOOK',
      switchLoading: false,
      columns: [{
        label: '名称',
        prop: 'name'
      }, {
        label: '开始时间',
        prop: 'beginTime'
      }, {
        label: '结束时间',
        prop: 'endTime'
      }, {
        label: '有效时长',
        prop: 'effectiveDay',
        customRender: props => {
          return props.row.effectiveDay + '天';
        }
      }, {
        label: '次数',
        prop: 'effectiveCount',
        customRender: props => {
          return props.row.effectiveCount === 0 ? '不限' : props.row.effectiveCount;
        }
      }, {
        label: '原价',
        prop: 'originalPrice'
      }, {
        label: '现价',
        prop: 'nowPrice'
      }, {
        label: '每单最高抵扣额',
        prop: 'deductionFee',
        customRender: props => {
          return props.row.deductionFee === 0.00 ? '全部抵扣' : props.row.deductionFee;
        }
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: '170px'
      }],
      topButtons: [{
        name: '新增',
        code: 'rideCard_add',
        type: 'primary',
        click: this.add,
        permissions: ['rideCard_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'rideCard_del',
        type: 'danger',
        click: this.Del,
        permissions: ['rideCard_del']
      }, {
        name: '编辑',
        code: 'rideCard_edit',
        click: this.Edit,
        permissions: ['rideCard_edit']
      }],
      searchItems: [{
        prop: 'name',
        label: '骑行卡名称'
      } /*{
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
        }*/]
    };
  },

  methods: {
    add() {
      this.$router.push('/rideCardAdd');
    },
    Edit(row) {
      this.$router.push({
        path: '/rideCardAdd',
        query: {
          id: row.id
        }
      });
    },
    Del(row) {
      ElMessageBox.confirm('确认要删除该骑行卡吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.rideCard.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};